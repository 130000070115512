.unstyledList {
    padding: 0;
    list-style: none;
}

.zigzag2 {

    > * {
        display: flex;
        flex-direction: row;

        > *:nth-of-type(1)
        {
            width: 40%;
        }

        > *:nth-of-type(2)
        {
            width: 60%;
        }
    }

    > *:nth-of-type(2n) {
        flex-direction: row-reverse;
    }
}

@include media('>=tablet') {
    .zigzag2-desktop {

        > * {
            display: flex;
            flex-direction: row;

            > *:nth-of-type(1)
            {
                width: 40%;
            }

            > *:nth-of-type(2)
            {
                width: 60%;
            }
        }

        > *:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
    }
}

.zigzag2big {

    > * {
        display: flex;
        flex-direction: row;

        > *:nth-of-type(1)
        {
            width: 60%;
        }

        > *:nth-of-type(2)
        {
            width: 40%;
        }
    }

    > *:nth-of-type(2n) {
        flex-direction: row-reverse;
    }
}

@include media('>Mdesktop') {
    .zigzag2big-desktop {

        > * {
            display: flex;
            flex-direction: row;

            > *:nth-of-type(1)
            {
                width: 60%;
            }

            > *:nth-of-type(2)
            {
                width: 40%;
            }
        }

        > *:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
    }
}

.rounded{
    border-radius: $border-radius;
    @include media('<=tablet') {
        border-radius: $mobile-border-radius;
    }
}

.rounded-vid{
    border-radius: 0px;
    overflow: hidden;
    margin: auto;
    
    width: 1000px;
    height: 900px;
    max-height: 500px;;
    
    //max-width: 80%;
    border: 0;

    @include media('<=menuXS'){
        width: 200px;
        height: 200px;
        margin: auto;
        //height: 200px;
    }

    @include media('>=menuXS' , '<tablet'){
        width: 1000px;
        height: 750px;
        margin: auto;
        //height: 200px;
    }
}

        

.content-spacing {
    > div {
      // Apply padding only if there is an image inside the div
      &:has(img) {
        padding: 2em;
      }
  
      p {
        padding: 0 5em;
      }
    }
  }

@include media('<=tablet') {
    .content-spacing {
        > div {
          // Apply padding only if there is an image inside the div
          &:has(img) {
            padding: 1em;
          }
      
          p {
            padding: 0 1em;
          }
        }
      }
}

.img100cover {
    width: 100%;
    //height: 100%;
    object-fit: cover;
}