// ----------------------------------------------------------------
// Desktop
// ----------------------------------------------------------------
@include media ('>=Mdesktop') {
    #team {
        section {
            max-width: 50%;
        }
    }

    .team-flex {
        display: flex;
        width: 100%;
        justify-content: center;

        .team-container {
            display: grid;
            margin-bottom: 150px;

            --offset-scale: 8;

            @media (max-width: 1400px) {
                grid-template-columns: repeat(3, auto);

                .team-member:nth-child(3n + 1) {
                    transform: translateY(calc(0% * var(--offset-scale)));
                }

                .team-member:nth-child(3n + 2) {
                    transform: translateY(calc(2.5% * var(--offset-scale)));
                }

                .team-member:nth-child(3n + 3) {
                    transform: translateY(calc(1% * var(--offset-scale)));
                }
            }

            @media (min-width: 1400px) and (max-width: 1600px) {
                grid-template-columns: repeat(4, auto);

                .team-member:nth-child(4n + 1) {
                    transform: translateY(calc(1.5% * var(--offset-scale)));
                }

                .team-member:nth-child(4n + 2) {
                    transform: translateY(calc(3% * var(--offset-scale)));
                }

                .team-member:nth-child(4n + 3) {
                    transform: translateY(calc(1.5% * var(--offset-scale)));
                }

                .team-member:nth-child(4n + 4) {
                    transform: translateY(calc(4% * var(--offset-scale)));
                }
            }

            @media (min-width: 1600px) and (max-width: 1900px) {
                grid-template-columns: repeat(5, auto);

                .team-member:nth-child(5n + 1) {
                    transform: translateY(calc(1.5% * var(--offset-scale)));
                }

                .team-member:nth-child(5n + 2) {
                    transform: translateY(calc(3% * var(--offset-scale)));
                }

                .team-member:nth-child(5n + 3) {
                    transform: translateY(calc(1.5% * var(--offset-scale)));
                }

                .team-member:nth-child(5n + 4) {
                    transform: translateY(calc(4% * var(--offset-scale)));
                }

                .team-member:nth-child(5n + 5) {
                    transform: translateY(calc(2% * var(--offset-scale)));
                }
            }

            @media (min-width: 1900px) {
                grid-template-columns: repeat(6, auto);

                .team-member:nth-child(6n + 1) {
                    transform: translateY(calc(0% * var(--offset-scale)));
                }

                .team-member:nth-child(6n + 2) {
                    transform: translateY(calc(2.5% * var(--offset-scale)));
                }

                .team-member:nth-child(6n + 3) {
                    transform: translateY(calc(3.8% * var(--offset-scale)));
                }

                .team-member:nth-child(6n + 4) {
                    transform: translateY(calc(2% * var(--offset-scale)));
                }

                .team-member:nth-child(6n + 5) {
                    transform: translateY(calc(3.5% * var(--offset-scale)));
                }

                .team-member:nth-child(6n + 6) {
                    transform: translateY(calc(1.5% * var(--offset-scale)));
                }
            }

            .team-member {
                width: 250px;
                height: 550px;
                background-color: transparent;
                text-align: center;
                border-radius: 50vw;
                padding: 2em 0.5em;
                margin: 2.4em;
                position: relative;

                img {
                    height: 100%;
                    width: 100%;
                    z-index: -1;
                    object-fit: cover;
                    border-radius: inherit;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                h1 {
                    font-size: 1.2em;
                    margin-bottom: 0;
                }

                p {
                    font-size: 0.9em;
                    margin-top: 0;
                }
            }
        }
    }
}

// ----------------------------------------------------------------
// Tablet and Mobile Styles
// ----------------------------------------------------------------
@include media ('<=Mdesktop') {
    #team-content {
        >p {
            max-width: 80%;
            margin-left: auto;
        }
    }

    .team-flex {
        display: flex;
        width: 100%;

        .team-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 2em;

            .team-member {
                width: 50%;
                height: 25em;

                background-color: transparent;
                text-align: center;
                //margin: 4em -1em;
                position: relative;
                padding: 0;

                // Align first team member to flex-start

                &:first-child{
                    margin-top: 2em !important;
                }

                &:nth-child(odd) {
                    border-radius: 50vw;
                    margin-top: -2em;

                    div {
                        text-align: center;
                        
                    }
                }

                

                // Align second team member to flex-end
                &:nth-child(even) {
                    border-radius: 50vw;
                    align-self: flex-end;
                    margin-top: -2em;

                    div {
                        text-align: center;
                    }
                }
                
                &:last-child{
                    margin-bottom: 4em;
                    margin-top: -2em;
                }

                img {
                    height: 100%;
                    width: 100%;
                    z-index: -1;
                    object-fit: cover;
                    border-radius: inherit;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                div {
                    position: relative;
                    bottom: -100%;
                    margin: 1em;

                    h1 {
                        font-size: 1.2em;
                        margin: 0;
                    }

                    p {
                        font-size: 0.9em;
                        margin: 0.5em 0;
                    }
                }
            }
        }
    }
}