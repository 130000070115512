// ----------------------------------------------------------------
// Desktop
// ----------------------------------------------------------------
#project {
    #next-prev-project {
        margin-top: 50px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        text-decoration: underline ;
    }
    #project-header {
        display: flex;
        flex-direction: row;
        justify-content: end;

        >div {
            width: 50%;
        }

        h1 {
            border-bottom: 1px solid $color;
        }

        #project-subtitle {
            display: flex;
            justify-content: space-between;
            align-items: center;

            >* {
                max-width: 45%;
            }


            p {
                font-size: 1em;
            }
        }
    }

    #project-sections {
        section {
            >div {
                &:has(img) {
                    padding: 2em;
                }
            }

            .project-synopsis {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    #project-video-sections {
        margin:auto;

        .framelink{
            width: 1000px;
            margin:0;
            height: 500px;
        }
        section {
            display: flex;
            justify-content: center;
            align-items: center;
            //max-width: 100%;
            //height: 50em;
            &:has(video) {
                padding: 2em;
            }
            &:has(iframe){
                padding: 2em;
                width: 100%;
            }
        }
        
    }

    a{
        display: inline-block;
        text-decoration: none;
    }
    a img {
        display: block;
        height: auto;
        width: 200px;
        margin: 0 5px 0 5px

    }
    .store-icons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

// ----------------------------------------------------------------
// Mobile
// ----------------------------------------------------------------
@include media('<=tablet') {

    #project {
        #project-header {


            >div {
                width: 100%;
            }

            h1 {
                border-bottom: 1px solid $color;
            }

            #project-subtitle {

                flex-direction: column;
                justify-content: space-between;

                >* {
                    max-width: 100%;
                }
            }
        }

        #project-sections {
            section {
                >div {
                    justify-content: flex-start;
                    width: 100%;

                    img {
                        width: 75vw;
                        height: 100%;
                        object-fit: cover;
                    }

                }

            }

            section:nth-of-type(1) {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        #project-video-sections {
            section {
                &:has(video) {
                    width: 100%;
                }
                &:has(iframe){

                    padding: 2em;
                    padding-top: 0;
                    padding-bottom:0;
                    width: 60%;
                    margin: auto;
                    margin: 10px auto 10px auto;
                    border: 10px;
                }
            }
        }
        a{
            display: inline-block;
            text-decoration: none;
        }
        a img {
            display: block;
            height: auto;
            width: 100px;
            margin: 0 2px 0 2px
    
        }
    }
}