// ----------------------------------------------------------------
// Desktop
// ----------------------------------------------------------------
footer {
    display: flex;
    justify-content: space-between;
    background-color: black;
    margin: 0 -5em;
    padding: 0 2em;

    h2,
    h1 {
        margin-bottom: 0.2em;
    }
    >div {
        display: flex;
        flex-direction: column;
        margin: 2.5em;
        max-width: 100%;

        img {
            max-width: 75%;
            max-height: 75%;
            margin: auto;
        }
    }

    .footer-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;


        >* {
            padding: 2em 0;
        }

        >:last-child {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid $color;


        }

    }
}

// ----------------------------------------------------------------
// Mobile
// ----------------------------------------------------------------
@include media('<=Mdesktop') {
    footer {
        display: flex;
        flex-direction: column;
        margin: 0 -3em;
        justify-content: center;

        h2 {
            margin-bottom: 0.5em;
        }

        h1 {
            margin-bottom: 1em;
        }

        >div {
            font-size: 2vw;
            flex-direction: column;
            justify-content: center;
            font-size: 1.5vw;

            img {
                max-width: 65%;
                max-height: 65%;
            }

        }

        .footer-contact-links {
            flex-direction: column;


        }
    }
}

@include media('<=menuXS') {
    footer {
        display: flex;
        flex-direction: column;
        margin: 0 -3em;
        justify-content: center;

        h2 {
            margin-bottom: 0.5em;
        }

        h1 {
            margin-bottom: 1em;
        }

        >div {
            font-size: 2vw;
            flex-direction: column;
            justify-content: center;
            font-size: 3vw;

            img {
                max-width: 75%;
                max-height: 75%;
            }

        }

        .footer-contact-links {
            flex-direction: column;


        }
    }
}