@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@import '_utils.scss';
@import '_collection.scss';

@import './components/Header.scss';
@import './components/Footer.scss';
@import './components/Carousel.scss';
@import './components/Cursor.scss';
@import './components/ParticleBackground.scss';

@import './pages/Projects.scss';
@import './pages/Distribution.scss';
@import './pages/About.scss';
@import './pages/Team.scss';
@import './pages/Project.scss';
@import './pages/Contact.scss';


* {
    cursor: none !important;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    vertical-align: top;
    width: 100%;
    max-width: 100vw;
    color: $color;
    background-color: $bg-color;
    font-family: "Work Sans";
    margin: 0;
    padding: 0;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    
    header{
        @include media('>menuXS','<=Mdesktop') {
            height: 100px;
        }
        @include media('<=menuXS') {
            height: 100px !important;
        }
    }

    body {
        margin: 0;
        padding: 0 3em;
        @include media('<=tablet') {
            padding: 0 2em;
        }

        main {
            min-height: calc(100vh - 100px);

            @include media('<=tablet') {
                min-height: calc(100vh - 70px);
            }
        }
    }

}

h1 {
    font-size: $desktop-size-h1;
    text-transform: uppercase;
    @include media('<=tablet') {
        font-size: $mobile-size-h1;
    }
}

p {
    font-size: $desktop-size-p;
    @include media('<=tablet') {
        font-size: $mobile-size-p;
    }
    font-weight: 200;
}

h3 {
    font-size: $desktop-size-p;
    text-transform: uppercase;
    @include media('<=tablet') {
        font-size: $mobile-size-p;
    }
}

a {
    color: $color;
    text-decoration: none;

    * {
        pointer-events: none;
    }
}

input , select, textarea{
    background-color: $sec-bg-color;
    color: $color;
    border: 1px solid white;
    border-radius: 50vh;
    padding: 1em;
    appearance: none;
    -webkit-appearance: none;
    box-sizing: border-box;
}

#lang-select{
    padding: 10px 7.5px;
    align-content: center;
    background-color: white;
    color:black;
    font-weight: bolder;

    @include media('<=Mdesktop'){
        padding: 10px 10px;
    }
}

button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent; /* Default color */
    font-size: 1em;
    color: $color;
    padding: 1em 3em;
    border: 1px solid $color;
    border-radius: 50vh;

    max-width: 30em;

    .button-overlay {
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;
        border-radius: inherit;
        background-color: $color;
        transition: width 0.3s, height 0.3s;
        mix-blend-mode: difference;
    }

    &:hover .button-overlay {
        width: 100%; /* Make the overlay cover the button */
        height: 100%;
    }
}

video {
    width: 100%;
}



